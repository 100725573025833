import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"
import { getEBooksCoverUrl } from "utils/rsc-util"

const LinkMap = {
  "Functional & NonFunctional": "632a2a4ddc4d",
  "Gereksinim ve Sorunlar": "632a2a4ddc4d",
  "Teknolojik Gelişimi": "632a2a4ddc4d",
  "Generic, Dynamic": "632a2a4ddc4d",
  "Ekip İletişimi": "632a2a4ddc4d",

  "Yönetimsel Başarısızlıklar": "3b157ae9ca1",
  "Süreçsel Problemler": "3b157ae9ca1",
  "Mimari Problemler": "3b157ae9ca1",
  "Geliştirici Ekip": "3b157ae9ca1",
  "Teknolojik Gelişim ve Baskı": "3b157ae9ca1",

  "Technical Debt": "b5ec55b5159a",
  "Clean Code": "754fc7ccd5b8",
  Refactoring: "12a3af027843",
  "NextGen Enterprise": "57ceec47938e",
  "Techology Effect Arch": "5108c71fc623",
  "Organization Effect Arch": "2245fbf5bfca",
  "Min Viable Product": "308253337e4f",
  Mindmap: "77cab29df8bb",
  "Sketch, Wireframe, Mockup, Prototype": "394338279e0f",
  SOLID: "4991542a6b8d",
  "TDD,BDD": "30e225c29c14",
  "Reactive Manifesto": "113218b88ffd",
  "12 Factor App": "61645e68f114",
  "Jira Scrum Board": "58baeb72f973",
  "Pomodoro Technique": "bfc97456cb2e",
  ACID: "53f729f2bbb2",
  "CAP Theory": "8673e71c10fc",
  "ACID, BASE, CAP": "7764d6ebe239",
  RAID: "ead27c25bdae",
  BlockChain: "f245b6b69b00",
  "SoundCloud Microservice": "fe395b87600e",
  Dogfooding: "df0d26d4ba3",
  "AWS Well-Architected": "c686c44a5eb3",
  Pillars: "c686c44a5eb3",
  "Design Principle": "c686c44a5eb3",
  "Horizontal Slicing": "91c8a559af32",
}

const eBooks = [
  {
    title: "Yazılımda Kod ve Mimari Kalite",
    url: "https://learnreactui.dev/contents/yazilimda-kod-ve-mimari-kalite",
    img: getEBooksCoverUrl("075_yazilimda_kod_ve_mimari_kalite"),
  },
]

const storiesGroup = [
  {
    title: "Mimari ve Kod Kalitesi",
    storyPart: [
      {
        title: "Yazılım Mimarının 5 Yanlışı",
        postId: "632a2a4ddc4d",
      },
      {
        title: "Kod Kalitesini Neler Kötü Etkiler",
        postId: "3b157ae9ca1",
      },
      {
        title: "Teknik Borç (Technical Debt)",
        postId: "b5ec55b5159a",
      },
      {
        title: "Temiz Kod (Clean Code)",
        postId: "754fc7ccd5b8",
      },
      {
        title: "Refactoring",
        postId: "12a3af027843",
      },
    ],
  },
  {
    title: "Mimariyi Etkileyen Faktörler",
    storyPart: [
      {
        title: "Gelecek Nesil Şirketlerin Teknolojileri",
        postId: "57ceec47938e",
      },
      {
        title: "Teknolojinin Yazılım Mimarisine Etkisi",
        postId: "5108c71fc623",
      },
      {
        title: "Organizasyon Yapısının Mimariye Etkisi",
        postId: "2245fbf5bfca",
      },
      {
        title: "SoundCloud Mikroservis Geçişi",
        postId: "fe395b87600e",
      },
      {
        title: "AWS Well-Architected Framework",
        postId: "c686c44a5eb3",
      },
    ],
  },

  {
    title: "Veri Yönetimi",
    storyPart: [
      {
        title: "ACID",
        postId: "53f729f2bbb2",
      },
      {
        title: "CAP Teoremi",
        postId: "8673e71c10fc",
      },
      {
        title: "ACID, BASE, CAP",
        postId: "7764d6ebe239",
      },
      {
        title: "RAID",
        postId: "ead27c25bdae",
      },
      {
        title: "Blockchain",
        postId: "f245b6b69b00",
      },
    ],
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "Mimari Kalite",
  path: "mimari-kalite",
}

const SoftwareDevCyclesPage = () => {
  return (
    <StoryGroup
      title={context.title}
      eBooks={eBooks}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default SoftwareDevCyclesPage
